@font-face {
  font-family: OPTIImprovNewWideNine;
  src: url("./Assets/OPTIImprovNewWideNine.ttf");
}

@font-face {
  font-family: Grotta;
  src: url("./Assets/Grotta-Regular.ttf");
}
@font-face {
  font-family: Agrandir-GrandHeavy;
  src: url("./Assets/Agrandir-GrandHeavy.ttf");
}
@font-face {
  font-family: Agrandir-GrandLight;
  src: url("./Assets/Agrandir-GrandLight.ttf");
}
@font-face {
  font-family: Agrandir-WideLight;
  src: url("./Assets/Agrandir-WideLight.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

.home {
  color: whitesmoke;
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  background-color: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(238, 238, 238, 1) 34%,
    rgba(255, 255, 255, 1) 53%,
    rgba(0, 0, 0, 1) 76%,
    rgba(0, 0, 0, 1) 100%
  ) ;
}

.toggle-btn {
  position: fixed;
  top: 6%;
  left: 5%;
  cursor: pointer;
  z-index: 5;

}
 
.toggle-btn span {
  transition: all ease 400ms;
  position: fixed;
  display: block;
  width: 5vh;
  height: 5vh;
  background-color: lawngreen;
}

.toggle-btn .abierto1 {
   border-radius: 50%;
  
}

.toggle-btn .cerrado1 {
  height: 0.6vh;
  transform:translateY(200%) rotate(90deg) ;
 
}

.toggle-btn .cerrado2 {
  height: 0.6vh;
  transform: translateY(200%)rotate(0deg) ;
 
}


.nav {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 3;
  background-color: transparent;
  left: -100%;
  transition: 400ms all;
}

.nav-active {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 2;
  background-color: #000000;
  left: 0;
  transition: 400ms all;
}

.nav-content {
  height: 100%;
  width: 90%;
  align-items: center;
}

.nav-links ul {
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 100%;
  margin-top: 20vh;
}

.nav-links li {
  list-style: none;
  display: inline-block;
  margin: calc(100vh / 50) 5vw;
}

.nav-links li a {
  font-family: Agrandir-GrandHeavy;
  text-decoration: none;
  font-weight: 300;
  color: whitesmoke;
  font-size: 2em;
  text-transform: uppercase;
}

.nav-links li a:hover {
  color: rgb(41, 41, 41);
}

section {
  height: 100vh;
  width: 100vw;
  scroll-snap-align: center;
}

/*  ///////////////////////////////////////////////////// MENUQR  ///////////////////////////////// */
/*  //////////////////////////////////////////////////////////////////////////////////////////////// */
.img-qr-container {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: row;
}



.buttons-caption-qr-container {
  background-color: #000000;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
}

.button-qr {
  margin: auto auto auto 14%;
  font-family: Agrandir-GrandLight;
  font-size: 0.8em;
  padding: 10px 30px; 
  border-radius: 25px;
  width: 10%;
  text-align: center;
  background-color: transparent;
  color: lawngreen;
  border: 1px solid ;
}

.buttons-container-qr a {
  text-decoration: none;
}

.header-QR {
  font-family: Agrandir-GrandHeavy !important;
  font-size: 2em;
  margin:auto 7%;
}

.header-QR-desktop {
  font-family: Agrandir-GrandHeavy;
  font-size: 2.3em !important;
}

.QR-text-desktop {
  font-family: Agrandir-WideLight;
  font-size: 1em !important;
}

.QR-text-mobile {
  width: 42%;
  color:white;
  margin:auto auto auto 0;
  font-size: 0.7em;
  display: none;
}


.QR2 {
  background-color: transparent;
  color: lawngreen;
  border: 1px solid lawngreen;
}
.MenuQR *{
  box-sizing:content-box
}
.carrousel-container {
  overflow-x: scroll;
  height: 100vh;
  max-height: 100vh;
  background-image: url("./Assets/fondo-web.png");
  background-position: center;
  background-repeat: repeat;
  background-attachment: local;
  background-size: cover;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
}

.section-qr-container {
  max-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1.6fr 0.4fr;
  gap: 1px 1px;
  grid-template-areas: "." ".";
  scroll-snap-align: center;
}

.carrousel-container::-webkit-scrollbar {
  display: none !important;
}

.benefit-list {
  width: 231px;
  list-style: url("./Assets/plus-list.png");
}

.grid-container { height: 100%; margin: 0; }

.grid-container {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 1px 1px;
  grid-template-areas: 
    ". . ." ". . ." ". . .";
}

.grid-container > li {
  text-align: center;
  display: flex;
  align-self: center;
  justify-content: center;
  margin: auto;
  font-size: 1.3em;
  list-style-type: none;
  font-family: Agrandir-WideLight;
}

.references-container {
  margin: auto;
  background-color: white;
  width: 40vw;
  padding: 40px 20px;
  border-radius: 25px;
  height: 50%;
  display: flex;
  flex-direction: column;
}

.references-container * {
  margin: auto auto auto 0;
}

.references-container h1 {
  color: #7dff00;
    text-decoration: underline;
    font-size: 1.5em;
    letter-spacing: 3px;
    font-family: Agrandir-WideLight;
}

.references-container p,
h4 {
  color: #3e3b3b;
  font-style: italic;
  font-family: Agrandir-WideLight;
}

.arrow-png-menuqr {
  width: 1.2em;
}

#arrow-right {
  position: absolute;
  right: 10px;
  color:white;
  margin: auto;
  z-index: 3;
  font-size: 1.2em;
  transform: translate(-10px, 90vh);
  cursor: pointer;
}

.carrousel-container {
  overflow-x: scroll;
  height: 100vh;
  display: flex;
  flex-direction: row;
}
.carrousel-section {
  min-width: 100vw;
} 
 
 .img-qr {
    width: calc( 200px + 5vw ) ;
    margin: auto auto auto 0;
    /* padding-top: 30px; */
  }
  .img-qr-test{
    width: 270px;
    height: 490px;
    margin: auto;
  }

  .header-QR-mobile {
    font-size: 1.1em !important;
    color:white;
    font-family: Agrandir-GrandHeavy !important;
    margin: auto 5%;
    display: none;
  }

 .QR-desktop{
   display: flex;
   flex-direction: column;
   width: 30%;
   margin: auto auto auto 15%;
   text-align:left;
 }
 .QR-desktop *{
   margin-right: auto;
  font-size: 1.7em;
 }

 

 
  .benefit-list {
    padding: 0px 15px;
  }
  .benefit-list li {
    font-size: 1em;
  }

/*  ///////////////////////////////////////////////////// NOSOTROS  ///////////////////////////////// */
/*  //////////////////////////////////////////////////////////////////////////////////////////////// */


.Nosotros {
  margin: 10% auto;
  padding-top: 10%;
  height: 100vh;
  max-width: 90vw;
  align-self: baseline;
 
}
@keyframes example {
  from {color:white}
  to {color:black}
}

.nosotres-texto {
  font-family: Agrandir-GrandLight;
  color: black;
  word-wrap: break-word;
  letter-spacing: 0.08em;
  line-height: 1em;
  font-size: 3em;
  animation-name: example;
  animation-duration: 0.6s;
}



/*  ///////////////////////////////////////////////////// CONTACTO  ///////////////////////////////// */
/*  //////////////////////////////////////////////////////////////////////////////////////////////// */



#Contacto{
  display: grid;
  align-content: center;
  justify-content: center;
  grid-template-columns: 20% 20% 18% 18% 18%;
  grid-template-rows: 25% 25%;
  gap: 1px 1px;
  grid-template-areas: ". . . . ." ". . . . .";
}

.contact-logo {
  width: 350px;
  padding: 0 20%;
}

.contact-arrow {
  width: 60px;
  text-align: right;
}

.logo-flecha {
  text-align: center;
}

.bottom {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
}

#Contacto h3 {
  font-family: Agrandir-WideLight;
  font-size: 1.2em;
}

.grey {
  color: grey !important;
}

.mail {
  color: lawngreen !important;
}

/*  //////////////////////////////////////////SERVICIOS////////////////////////////////////////////////////// */

.marquee-wrap {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.marquee-wrap,
.marquee-wrap ul {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
}

.marquee-wrap > li {
  display: flex;
  align-items: center;
  width: var(--scroller-size);
  will-change: transform;
  animation: text-scroller 7500ms linear 0ms normal infinite;
  text-indent: 0;
  justify-content: space-around;
  -webkit-transition: all 3000ms linear 0ms;
  -moz-transition: all 3000ms linear 0ms;
  -ms-transition: all 3000ms linear 0ms;
  -o-transition: all 3000ms linear 0ms;
  transition: all 3000ms linear 0ms;
  white-space: nowrap;
  font-family: Agrandir-WideLight;
  color: lawngreen;
}

@keyframes text-scroller {
  100% {
    transform: translateX(calc(var(--scroller-size) * -0.5));
  }
}

.border {
  border-top: 2px solid lawngreen;
}

.marquee-wrap > li:nth-child(2) {
  animation-direction: reverse;
}

.marquee-wrap > li:nth-child(4) {
  animation-direction: reverse;
}

.marquee-wrap > li:nth-child(6) {
  animation-direction: reverse;
  border-bottom: 2px solid lawngreen;
}

.marquee-wrap > li > ul li {
  display: inline-block;
  padding: 0.5vh 4vw;
  font-size: 11.6vh;
  text-transform: uppercase;
}

:root {
  --scroller-size: 150vw;
}

@media only screen and (max-width: 600px) {
  :root {
    --scroller-size: 200vw;
  }
}

/*  //////////////////////////////////////////QUERY MOBIL////////////////////////////////////////////////////// */
@media screen and (max-width: 450px) {
  .nav-links li a {
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 300;
    color: whitesmoke;
  }

  .nosotres-texto {
    font-size: 1.5em;
    margin: 10% 2%;
    padding: 10% 2%;

  }

  .contact-logo {
    width: 200px;
    padding: 0 15%;
  }

  .contact-arrow {
    width: 50%;
  }

  .QRbutton {
    position: absolute;
    top: 65%;
    left: 45%;
    width: 150px;
    height: 150px;
    border-radius: 25%;
    color:#ff00d2;
    border: 4px solid;
    background-color: black;
    background-image: url(/src/Assets/laroviraQR.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .img-qr{
    width: 250px;
    margin: auto ;
  
  
  }
   
    .grid-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      list-style: url("./Assets/plus-list.png") !important;
    }
  
    .grid-container > li {
      align-self: baseline;
      margin:7px;
      font-size: 1em;
    }


    #Contacto {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
      align-content: center;
      justify-content: center;
      max-width: 90%;
      gap: 1px 1px;
      grid-template-areas: "logo general" "div-vacio1 social" "div-vacio2 studio" "div-vacio3 createdby" "logo-flecha allrights";
    }
  
    
  #Contacto > div {
      display: flex;
      font-size: 0.75em
    }
  .createdby {
  grid-area: createdby;
}

.collabostudio {
  display: none;
}

.logo{
  grid-area: logo;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 4%;
}

.general {
  grid-area: general;
  flex-flow: column;
  justify-content: flex-end;
}

.social {
  grid-area: social;
  flex-flow: column;
  justify-content: flex-end;
}

.div-vacio2 {
  grid-area: div-vacio2;
}

.studio {
  grid-area: studio;
  flex-flow: column;
justify-content: flex-end;
}

.logo-flecha {
  grid-area: logo-flecha;
  width: 60%;
}

.allrights {
  grid-area: allrights;
  align-self: flex-start;
  margin-top: 15%
}

.header-QR {
  margin: auto auto auto 14%;
  font-size: 2em !important;
 }

 .references-container {
  width: 60vw;
}

.references-container h1 {
    font-size: 1.5em;
    letter-spacing: 3px;
}
}

/* //////////////////////// MEDIA QUERY IPHONE X   ////////////////////////*/
@media only screen 
    and (device-width : 375px) 
    and (device-height : 812px) 
    and (-webkit-device-pixel-ratio : 3) { 
  .nosotres-texto {
    font-size: 1.68em;
    margin: 10% 2%;
    padding: 10% 2%;
  }
    }

/* //////////////////////// MEDIA QUERY IPHONE 5   ////*/

@media screen and (device-aspect-ratio: 40/71) {
  .nosotres-texto {
    font-size: 1.35em;
    margin: 10% 2%;
    padding: 10% 2%;
  }
}

/* //////////////////////// MEDIA QUERY IPHONEPLUS   ////*/

@media only screen 
and (min-device-width : 414px) 
and (max-device-width : 736px) {
    .nosotres-texto {
    font-size: 1.65em;
    margin: 10% 2%;
    padding: 10% 2%;
  }
}

/* //////////////////////// MEDIA QUERY GALAXY   ////*/
@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 3) 
  and (orientation: portrait) {
    .nosotres-texto {
    font-size: 1.5em;
    margin: 9% 2%;
    padding: 9% 2%;
  }
}

/* //////////////////////// MEDIA QUERY IPADPRO  ////*/

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)
and (-webkit-min-device-pixel-ratio: 2) {
  .nosotres-texto {
  font-size: 3.6em;
  margin: 8% 2%;
  padding: 8% 2%;
}
}


/* //////////////////////// MEDIA QUERY 600  ////*/

@media screen and (max-width: 600px) {

  .QR-desktop, .button-qr  {
     display: none;
   }
   .img-qr{
     margin: auto;
   }
 .QR-text-mobile, .header-QR-mobile{
   display: inline;
   font-family: Agrandir-WideLight;
 }

 .header-QR {
  margin: auto auto auto 14%;
  font-size: 1.2em !important;
 }

 #Contacto {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  gap: 1px 1px;
  grid-template-areas: "logo general" "div-vacio1 social" "div-vacio2 studio" "div-vacio3 createdby" "logo-flecha allrights";
}
.createdby {
grid-area: createdby;
}
.logo{
grid-area: logo;
}

.general {
grid-area: general;
}

.social {
grid-area: social;
}

.div-vacio2 {
grid-area: div-vacio2;
}

.studio {
grid-area: studio;
}

.logo-flecha {
grid-area: logo-flecha;
}

.allrights {
grid-area: allrights;
}
 }

/*  //////////////////////////////////////////LINK PREDETERMINED STYLE OVERWRITE////////////////////////////////////////////////////// */

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}
